/* global twentyseventeenScreenReaderText */
/**
 * Theme functions file.
 *
 * Contains handlers for navigation and widget area.
 */

(function( $ ) {

    $(document).ready(function(){

        var mainMenu = $('#site-navigation');
        var navWrap = $('.navigation-wrapper');
        var toggleButton = $('.menu-toggle');

        toggleButton.on('click', function() {
            var uri = $(this).data('template');
            if( $(this).hasClass('is-open') ){
                $(this).find('img').attr('src', uri + '/assets/images/menu-bar.svg');
            } else {
                $(this).find('img').attr('src', uri + '/assets/images/menu-x.svg');
            }
            mainMenu.toggleClass('is-visible');
            navWrap.toggleClass('is-visible');
            $(this).toggleClass('is-open');

        });


        if( $('.function-list-complete').length > 0 ) {

            var $functionList = $('.function-list-complete');
            var $link = $functionList.find('.function-list__item');

            $link.on('click', function ( e ) {
                e.preventDefault();
                var href = $(this).attr('href');
                var div = $(href);
                $('html, body').animate({
                    scrollTop: div.offset().top - 100
                }, 1000);
            });
        }

        if(window.innerWidth > 1500){
            toggleButton.click();
        }

        $(window).on('resize', function(){
            if(window.innerWidth > 1500){
                toggleButton.click();
            }
        })


    });

})( jQuery );
